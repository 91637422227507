/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/

html {
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	margin: 0;
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
}

@font-face {
    font-family: 'sinkin_sans200_x_light';
    src: url('../media/fonts/sinkinsans-200xlight-webfont.woff2') format('woff2'),
         url('../media/fonts/sinkinsans-200xlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sinkin_sans300_light';
    src: url('../media/fonts/sinkinsans-300light-webfont.woff2') format('woff2'),
         url('../media/fonts/sinkinsans-300light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'sinkin_sans700_bold';
    src: url('../media/fonts/sinkinsans-700bold-webfont.woff2') format('woff2'),
         url('../media/fonts/sinkinsans-700bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


a { color: #254c2f;}
ul { padding-left:20px;}
img { max-width: 100%;}

h2 { font-family: 'sinkin_sans200_x_light'; font-size:37px;  color: #254c2f; text-transform: uppercase; margin: 0px 0px 20px;  }
h3 { font-family: 'sinkin_sans300_light'; font-size: 22px; color: #254c2f;  }
h2,h3,h4,h5 { margin-top: 0px; color: #254c2f;}
h4 { font-family: 'sinkin_sans700_bold'; }
p { margin-bottom:25px; line-height: 24px;}

/*=================== Header =============*/

.site-header { width: 100%; float: left; padding: 20px 0px 30px; }

.main-navigation { text-align: center;}
.main-navigation .menu-main-left-menu-container { float: left}
.main-navigation .menu-main-right-menu-container { float: right}
.main-navigation .menu-main-left-menu-container, .main-navigation .menu-main-right-menu-container { border-bottom: 1px solid #959595;width:37%;border-top: 1px solid #959595; margin-top: 25px; }
.main-navigation ul { padding-left: 0px; margin-bottom:0; }
.main-navigation ul li { display: inline-block; position: relative; }
.main-navigation ul li a { font-size: 17px; color: #254c2f; font-family: 'sinkin_sans200_x_light'; text-transform: uppercase; padding: 18px 27px; display: inline-block; }
.main-navigation ul li > a:hover, .main-navigation ul li.current-menu-ancestor > a, .main-navigation ul li.current-menu-item > a { color: #000; }
.main-navigation ul li a:hover { text-decoration: none}


.main-navigation ul li ul.sub-menu { display: none; }
.main-navigation ul li.open > ul.sub-menu,.main-navigation ul li:hover > ul.sub-menu { display: block; position: absolute; top: 100%; left: 0; width: 200px; background-color: #fff; padding: 5px 0; z-index: 2; text-align: left; margin-top: 0px; }
ul.sub-menu li { display: block; padding: 0 5px !important; }
ul.sub-menu li a { padding: 5px 10px !important; }
ul.sub-menu li:hover > ul.sub-menu,ul.sub-menu li.open > ul.sub-menu { top: 0; left: 100%; padding: 0 0 5px; }

.close-menu { display: none}

/*========================= banner =============*/

.banner-section { float: left; width: 100%; position: relative;}
.banner-section .banner-img { background-size: cover; background-repeat: no-repeat; background-position: top center; width: 100%; display: block;}
.banner-section .banner-img img { opacity: 0; display: block}
.banner-section .banner-title { position: absolute; bottom: 0; left: 0px; width: 100%; background-color: rgba(255,255,255,0.7); text-align: center;}
.banner-section .banner-title h2 { color: #254c2f; font-size: 36px; padding: 30px 0px; margin: 0px; text-transform: uppercase; letter-spacing: 0.5px; }

.banner-section .banner-title:before {content: "";display: inline-block;border-top: 1px solid #b2b1b0;width: 100%;position: absolute;top: 10px;height: 1px;left: 0px;}

.service-section { float: left; width: 100%; padding: 60px 0px 35px; }
.service-section .service-block { text-align: center; width: 100%; padding: 5px; border: 1px solid #b1b1b1; margin-bottom: 25px; }
.service-section .service-block h3 { font-family: 'sinkin_sans700_bold'; text-transform: uppercase; padding:30px 0px 20px; margin: 0px; letter-spacing: 0.5px; }
.service-block .img {border-top: 20px solid #254c2f;display: block;width: 100%;}
.service-block .img img { display: block; width: 100%; }
.service-section .service-block a:hover { text-decoration: none;}

.testimonial-section { float: left; width: 100%; border-top: 1px solid #cccccc; padding: 30px 0px ; }
.testimonial-section .testimonial-block { float: left; width: 100%; padding-right: 50px; position: relative; padding-bottom: 30px; }
.testimonial-section .testimonial-block h2 { font-family: 'sinkin_sans200_x_light'; text-transform: uppercase; }
.testimonial-section .testimonial-block:after { content: ""; position: absolute; right:-20px; float: left; height: 100%; border-right: 1px solid #959595; top: 0;}
.testimonial-section .testimonial-block p strong{  color: #254c2f; }
.testimonial-section .page-link { margin-top:35px; width: 100%; text-align: right; }
.testimonial-section .page-link .button {width: 375px;display: inline-block;background-color: #254c2f;color: #fff;font-size: 22px;text-transform: uppercase;font-family: 'sinkin_sans700_bold';text-align: center;padding: 15px 20px 17px;margin-bottom: 22px;}
.testimonial-section .page-link .button:last-child { margin-bottom: 0px;}
.testimonial-section .page-link .button:hover { text-decoration: none}

/*====================== Footer ============== */

.site-footer { width: 100%; float: left; background-size: cover; padding: 25px 0px 40px; position: relative; margin-top: 50px; }
.site-footer:after {content: "";display: inline-block;width: 100%;border-top: 1px solid #959595;position: absolute;top: -10px;left: 0;}
.site-footer .footer-main { max-width: 1500px;width: 100%;margin: 0 auto;background-color: rgba(255, 255, 255, 0.7);overflow: hidden;padding: 25px 75px 15px; position: relative; }
.site-footer:before { content: "";display: inline-block;width: 100%;height: 100%;position: absolute;left: 0px;top: 0;background-color: rgba(204, 204, 204, 0.81); }
.site-footer .footer-logo { text-align: left;width: 250px;max-width: 100%;padding: 25px 0px;}
.site-footer .footer-logo-right { text-align: left;width: 250px;max-width: 100%;padding: 25px 0px 25px 20px;}
.site-footer .footer-contact {padding-left: 20px; width:380px; padding-top: 15px; }
.site-footer .footer-contact h3 { margin: 0px; text-transform: uppercase; font-family: 'sinkin_sans700_bold'; font-size: 19px;  color: #254c2f; }
.site-footer .footer-contact p { color: #254c2f;}
.site-footer .footer-col { display: inline-block;vertical-align: middle;  border-right: 1px solid #254c2f; }
.site-footer .footer-col:last-child { border-right: 0px;}

.site-footer .site-info { width: 100%;text-align: center;position: relative;font-size: 15px; padding-top: 15px; }

.footer-contact-numbers {float: left;padding-left: 20px; padding-top:10px; padding-right: 20px;}
.footer-contact-numbers a {display: block;padding-left: 45px;font-family: 'sinkin_sans700_bold'; font-size: 19px;margin-bottom:30px;text-transform: uppercase; position: relative; margin-top: 15px; display:block;}
.footer-contact-numbers a:hover { text-decoration: none; color: #254c2f; }
.footer-contact-numbers a.phone-icon:before {content: "";display: inline-block;background: url(../media/phone-icon.png) no-repeat;width: 27px;height: 29px;position: absolute;left: 0px;background-position: right center;}
.footer-contact-numbers a.msg-icon::before { content: "";display: inline-block;background: url(../media/msg-icon.png) no-repeat;width: 27px;height: 29px;position: absolute;left: 0px;top: 4px; }

.main-content {float: left;width: 100%;margin:35px 0px 0;}

.admin-list { float: left; width: 100%; margin-top:15px;}
.admin-block { float: left; width: 100%; margin-bottom: 35px; border-bottom: 1px solid #959595; }
.admin-block:last-child { border-bottom: 0px; margin-bottom: 0px;}
.admin-block .admin-img { width: 220px; float: left; margin-right:45px; border: 1px solid #959595; padding: 5px; }
.admin-block .admin-img img { width:100%; }
.admin-block .admin-content { width: calc(100% - 265px); float: left; }
.admin-block .admin-content h4 { color: #214729; font-family: 'sinkin_sans700_bold'; margin: 0px; }
.admin-block .admin-content strong { display: block;}

.panel-default { border: none; box-shadow: none;}
.panel-default > .panel-heading { color: #fff; background-color: #254c2f;  border:1px solid #254c2f; font-size: 18px; border-radius: 0px; padding: 15px 20px;  }
.panel-default .panel-heading a { color: #fff; text-transform: uppercase; font-family: 'sinkin_sans700_bold'; font-size: 18px; display: block}
.panel-default .panel-heading a:hover, .panel-default .panel-heading a:focus { text-decoration: none;}
.panel-body { padding:30px 0px 20px;}
.panel-default .panel-heading a .arrow { background: url(../media/arrow.png) no-repeat;width: 21px;height: 21px;display: inline-block;vertical-align: sub;margin-left: 20px;}
.panel-default.active > .panel-heading { background-color: #fff; }
.panel-default.active .panel-heading a { color: #254c2f;}
.panel-default > .panel-heading + .panel-collapse > .panel-body { border-top: 0px;}
.panel-default.active .panel-heading a .arrow { background-position: bottom center; vertical-align: baseline; height: 17px;     }

.thumbnail-list .row { margin-left: -26px;margin-right: -26px; margin-bottom: 45px; position: relative; overflow: hidden;}
.thumbnail-list .col-sm-4 {padding-left: 26px;padding-right: 26px;}
.thumbnail-block { width: 100%; float: left; margin-bottom:15px; }
.thumbnail-block .thumbnail-img { width: 100%; float: left; border: 1px solid #959595; padding: 5px; }
.thumbnail-block h4 { text-transform: uppercase; margin: 15px 0 0px; display: inline-block; width: 100%; line-height: 1.2; }
.panel-group .panel + .panel {margin-top: 15px;}
.panel-group { margin-bottom: 20px;margin-top: 40px;display: block;}

.thumbnail-list .row:after { content: ""; display: inline-block; width:calc(100% - 50px); border-bottom: 1px solid #959595; left:25px; position: absolute; bottom: 0px;}
.thumbnail-list .row:last-child:after { display: none}
.thumbnail-list .row:last-child { margin-bottom: 0px;}
.post-image { float: left; padding-right: 20px; width: 450px; }

.field-wrap input[type="button"] {border:0; width: 300px;display: inline-block;background-color: #254c2f;color: #fff;font-size: 18px;text-transform: uppercase;font-family: 'sinkin_sans700_bold';text-align: center;padding: 15px 20px 17px;margin-bottom: 22px;}
.main-content img.alignleft {float: left; margin-right:45px; border: 1px solid #959595; padding: 5px; }

hr {height: 1px; color: #959595; background-color: #959595; border: none; }
