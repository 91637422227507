.slider{width:100%;margin:auto;}
.slick-slide img{width:100%;}
.slick-slider{position:relative;display:block;box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent;}
.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0;}
.slick-list:focus{outline:none;}
.slick-list.dragging{cursor:pointer;cursor:hand;}
.slick-slider .slick-track,.slick-slider .slick-list{-webkit-transform:translate3d(0, 0, 0);-moz-transform:translate3d(0, 0, 0);-ms-transform:translate3d(0, 0, 0);-o-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);}
.slick-track{position:relative;top:0;left:0;display:block;margin-left:auto;margin-right:auto;}
.slick-track:before,.slick-track:after{display:table;content:'';}
.slick-track:after{clear:both;}
.slick-loading .slick-track{visibility:hidden;}
.slick-slide{display:none;float:left;height:100%;min-height:1px;}
[dir='rtl'] .slick-slide{float:right;}
.slick-slide img{display:block;}
.slick-slide.slick-loading img{display:none;}
.slick-slide.dragging img{pointer-events:none;}
.slick-initialized .slick-slide{display:block;}
.slick-loading .slick-slide{visibility:hidden;}
.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent;}

.slick-arrow {
    display: none !important;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); z-index: 1; cursor: pointer;
}
button.slick-prev.slick-arrow{left: 0;}
button.slick-next.slick-arrow{right: 0;}

 ul.slick-dots {position: absolute;right: 27px;bottom: 20px;left:auto;width: auto;text-align: left;    display: none !important;}
 ul.slick-dots li {display: inline-block;margin: 0 3px;}
 ul.slick-dots li button{margin: 0 8px;font-size:0; position:relative;cursor: pointer; background:transparent; border:none;}
 ul.slick-dots li button:after{content:"";width: 16px;height: 16px;border-radius:50%;border: 4px solid #000;
 cursor: pointer;background:  #000; position:absolute; top:0; left:0;}
 ul.slick-dots li.slick-active button{outline: none !important;}
ul.slick-dots li.slick-active button:after{background: #fff; }
