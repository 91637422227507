
@media screen and (min-width:1366px) {
    .container { width:1280px; }
    .main-navigation .menu-main-left-menu-container, .main-navigation .menu-main-right-menu-container {width: 35%;}
    .main-navigation ul li a { padding: 18px 20px;}
}

@media screen and (max-width:1550px) {
    .site-footer .footer-main { padding: 25px 30px 15px; }
}

@media screen and (min-width:1500px) {
    .container { width:1410px; }
}

@media screen and (max-width:1366px) {
    .main-navigation ul li a { padding: 18px 20px;}
    .logo { max-width: 270px; margin: 0 auto;}
    .service-section .service-block h3 {font-size: 18px;}
    .testimonial-section .page-link .button {width:300px;font-size: 18px;}
    .site-footer .footer-main { padding: 25px 150px 15px; }
    .site-footer .footer-contact-numbers { padding-left: 0px; }
    .site-footer .footer-col +  .footer-col { border-right: 0px;}

}

@media screen and (max-width:1280px) {
    .main-navigation .menu-main-left-menu-container, .main-navigation .menu-main-right-menu-container  { padding: 0px; margin-top: 10px; }
    .main-navigation ul li a { padding: 14px 15px;font-size: 15px;}
    .logo { max-width: 215px;}
    .banner-section .banner-title h2 { font-size: 30px;}
    .service-section .service-block h3 { font-size: 16px; letter-spacing: 0px;}
    .testimonial-section .testimonial-block { padding-right: 10px; }
    .testimonial-section .page-link .button { width: 260px;font-size: 16px;padding: 10px 20px 10px;}
    .site-footer .footer-logo { width: 245px; }
    .site-footer .footer-logo-right { width: 245px; padding-left: 15px; }
    .site-footer .footer-contact { padding-left: 15px;width: 375px;padding-top: 15px;}
    .site-footer .footer-main { padding: 25px 150px 15px; }
    .footer-contact-numbers { padding-left: 0; width: 100%;}
    .site-footer .footer-col +  .footer-col { border-right: 0px;}
    .footer-contact-numbers a { font-size: 16px; }
    .footer-contact-numbers { padding-left: 10px;}

}

@media screen and (max-width:992px) {
    .main-navigation ul li a { padding: 10px 6px; font-size: 12px;}
    .main-navigation .menu-main-left-menu-container, .main-navigation .menu-main-right-menu-container { padding: 0px; margin-top: 15px; width: 33%; }
    .service-section .service-block { margin-bottom: 40px;}
    .banner-section .banner-title h2 { font-size: 24px; }
    h2 { font-size: 32px; }
    .testimonial-section .page-link .button { width: 95%;font-size: 14px;padding: 10px 20px 10px;margin-left: 10px;float: right;}
    .site-footer .footer-main { padding: 25px 30px 15px; }
    .footer-contact-numbers { padding-left: 0; width: 100%;}
    .site-footer .footer-col +  .footer-col { border-right: 0px;}
    .site-footer .footer-logo-right { width: 245px; padding-left: 15px; }
    .thumbnail-list .row { margin-left: -16px; margin-right: -16px; margin-bottom:30px;}
    .thumbnail-list .col-sm-4 { padding-left: 16px; padding-right: 16px;}
    .thumbnail-block h4 { font-size: 16px;}
    .thumbnail-block p { font-size: 16px; line-height: normal}
    .thumbnail-list .row::after { width: calc(100% - 32px); left: 16px; }

}
@media screen and (max-width:767px) {
    .bar1, .bar2, .bar3 {width: 26px;height:3px;background-color: #959595; margin:5px 0;-webkit-transition: 0.4s;transition: 0.4s;}
    .menu-bar { border-bottom: 1px solid #bfbfbf; padding:8px 0px 5px;  width: 100%; text-align: center; display: inline-block; }
    .site-header { padding-top: 0px;}
    .toggle-menu:after { content: "MENU";display: inline-block;font-size: 20px;font-family: 'sinkin_sans200_x_light'; position: absolute;top: 0px;right: 0;}
    .toggle-menu {  position: relative; width: 110px; display: inline-block; }
    .main-menu { position: fixed;right: -280px;z-index: 99999;top: 0;width:280px;-webkit-transition: all 0.3s ease-in-out 0s;transition: all 0.3s ease-in-out 0s;padding-top:10px;height: 100%;overflow: auto; background-color: #fff;margin-top: 0; box-shadow: inset 0px 0px 10px rgba(0,0,0,0.6); }
    .site { position: relative; -webkit-transition: all 0.3s ease-in-out 0s;transition: all 0.3s ease-in-out 0s; left: 0px; }
    .open-menu .site { left: -280px;  }
    .open-menu .main-menu { right: 0px;}
    .close-menu {float: right;margin-right: 20px;}
    .close-menu svg { fill: #959595; }
    .open-menu .site::before { content: "";display: block;width: 100%;position: fixed;width: 100%;height: 100%;background-color: #000;z-index: 1;opacity: 0.7;}
    .logo { max-width: 215px; margin-top: 20px; }
    .banner-section .banner-title { position: relative; background-color: #fff;}
    .banner-section .banner-title::before { display: none}
    .banner-section .banner-title h2 {font-size: 24px;padding: 20px 10px;line-height: 30px;font-family: 'sinkin_sans200_x_light';max-width: 250px;margin: 0 auto;}
    .service-section { float: left;width: 100%;padding: 10px 0px;}
    .banner-section .banner-img { min-height:100px; }
    .service-section {float: none;width: 100%;padding: 10px 0px 30px;display: block;clear: both;max-width:260px;margin: 0 auto;}
    .service-section .service-block h3 { font-size: 14px;letter-spacing: 0px;padding: 15px 0px 12px;}
    .service-section .service-block { margin-bottom: 13px;}
    .testimonial-section .testimonial-block h2 { font-size:34px; margin-top: 5px; }
    body { font-size: 14px; }
    .testimonial-section .page-link .button { width: 100%;font-size: 15px;padding: 12px 20px 13px;margin-left: 0;float: none;display: inline-block;}
    .site-footer {margin-top: 30px; padding: 35px 0px 30px; }
    .site-footer .footer-main { padding:20px 20px 5px; text-align: center; }
    .site-footer .footer-col { border: none;}
    .site-footer .footer-contact { padding-left: 0;width: 100%;padding-top: 15px;}
    .site-footer .footer-contact { padding-left: 0;width: 100%;padding-top: 10px;}
    .site-footer .footer-contact h3 { font-size: 15px; margin-bottom: 5px;}
    .site-footer .footer-contact p br { display: none}
    .site-footer .footer-contact p { font-size: 12px; line-height: 19px; }
    .site-footer .site-info { font-size: 12px;}
    .footer-contact-numbers { padding-right: 0px;}
    .footer-contact-numbers a { font-size: 15px;padding-left: 30px; margin-top: 7px}
    .footer-contact-numbers a.phone-icon::before { background-size: 14px; width: 30px; height: 26px; }
    .footer-contact-numbers a.msg-icon::before { top: 4px; background-size: 20px; width: 22px; height: 22px; }
    .site-footer .footer-logo { width: 190px; padding: 15px 0px; }
    .site-footer .footer-logo-right { width: 190px; padding: 15px 0px; }
    .main-navigation ul { padding: 0px;margin-top: 0;width: 100%;border:0px; margin-bottom: 0px;}
    .main-navigation ul li { display: inline-block;width: 100%;text-align: center; border-bottom: 1px solid #959595; }
    .main-navigation ul li a { display: block}
    .testimonial-section .testimonial-block:after { display: none}
    .testimonial-section .testimonial-block { padding-right: 0;}
    .main-content { margin: 25px 0px 0;}
    h2 { font-size:25px; }
    .admin-block .admin-content { width: 100%; float: left; margin-top: 20px;}
    .admin-block .admin-img {  float: none; margin: 0 auto;}
    h4, .h4 {font-size: 14px;}
    p {margin-bottom: 15px;line-height: 18px;}
    .admin-block { margin-bottom: 20px;}
    .admin-list { margin-bottom: 40px;}
    .panel-group { margin-bottom: 40px; margin-top: 35px; }
    .panel-default .panel-heading a { font-size: 15px;}
    .panel-default .panel-heading a .arrow {margin-left: 14px; background-size: 17px; background-position: 0px 3px;}
    .panel-default > .panel-heading { padding: 10px 15px; }
    .thumbnail-block { margin-bottom: 35px; }
    .thumbnail-block h4 { font-size: 14px; line-height: 20px; }
    .thumbnail-block p {font-size: 14px;line-height: normal;}
    .panel-default.active .panel-heading a .arrow { height: 17px;}
     .post-image { float:none;padding-right:0;display: block;margin-bottom: 20px; width:100%;}
    .main-navigation .menu-main-left-menu-container, .main-navigation .menu-main-right-menu-container { border-bottom: none; width: 100%; border-top: none; margin-top: 0; }

	.thumbnail-list { padding: 0 6px; }
	.thumbnail-list .row { margin-left: -16px; margin-right: -16px; margin-bottom:30px;}
	.thumbnail-list .col-sm-4 { padding-left: 10px; padding-right: 10px;}
	.thumbnail-img img { width: 100%; }

	.main-navigation ul li.open > ul.sub-menu, .main-navigation ul li:hover > ul.sub-menu { display: block; position: relative; top: 0; left: 0; width: 100%; background-color: transparent;
    padding: 0; z-index: 2; text-align: left; margin-top: 0px; border-top: 1px solid #959595 }
	ul.sub-menu li:last-child { border-bottom: 0px none; }
	ul.sub-menu li a { padding: 10px !important ;}
	.main-navigation ul li.menu-item-has-children > a:after { content: ""; display: inline-block; width: 14px; height: 8px; background-image: url(../media/menu-icon.png); background-repeat: no-repeat; margin-left: 10px; }
    .close-menu { display: block; }
    .container { padding-left: 30px; padding-right: 30px; }
    .testimonial-section { border-top:none; position: relative;}
    .testimonial-section:before { content: ""; display: inline-block;  border-top:1px solid #cccccc; width:calc(100% + 60px); position: absolute; left:-30px; top: 0px;}
    .panel-body { padding: 35px 0px 20px;}
    .thumbnail-list .row::after, .site-footer:after { display: none; }
    .thumbnail-list .row { margin-bottom: 0px;}
    .admin-block { border-bottom: 0px; position: relative}
    .admin-block .admin-content h4 { margin: 0px 0 5px; line-height: 20px;}
    .admin-block:before { content: ""; display: inline-block;  border-bottom:1px solid #cccccc; width:calc(100% + 60px); position: absolute; left:-30px; bottom: 0px;}
    .admin-block:last-child:before { display: none; }

}
